<template>
  <v-card v-if="userCanInviteNewUsers" max-width="600">
    <v-card-title>Invite New User</v-card-title>
    <v-form>
      <v-row>
        <v-col>
          <v-text-field
            v-model="emailAddressToInvite"
            label="Email Address"
            class="mx-5 mt-0"
            :rules="rulesForEmailAddressToSendInvitationTo"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            class="ma-5 mt-0"
            :disabled="!enableSendInvitationButton"
            @click="sendRequestToServerToSendInvitation"
          >
            Send invitation
          </v-btn>
        </v-col>
        <v-col>
          <v-progress-circular v-if="showSpinner" indeterminate />
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar
      v-model="showNotificationThatInvitationWasSent"
      color="success"
      :timeout="3000"
    >
      Invitation sent!
    </v-snackbar>
  </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { emailFormatRule } from '@/utils/form-rules'
import { inviteUser } from '@/api/auth'

export default {
  components: {},
  data: function () {
    return {
      emailAddressToInvite: '',
      showNotificationThatInvitationWasSent: false,
      showSpinner: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['permittedActions', 'userCanInviteNewUsers']),
    ...mapState('account', ['emailDomain']),
    enableSendInvitationButton: function () {
      return (
        this.emailAddressToInvite &&
        this.emailAddressToInvite.endsWith(this.emailDomain)
      )
    },
    rulesForEmailAddressToSendInvitationTo() {
      return [
        emailFormatRule,
        (v) =>
          v.endsWith(this.emailDomain) ||
          "Email address must end with the account's domain.",
      ]
    },
  },
  methods: {
    sendRequestToServerToSendInvitation: function () {
      const vm = this

      this.showSpinner = true
      inviteUser({ email: this.emailAddressToInvite }).then(() => {
        vm.showNotificationThatInvitationWasSent = true
        vm.emailAddressToInvite = ''
        vm.showSpinner = false
      })
    },
  },
}
</script>
