<template>
  <div>
    <v-card>
      <v-data-table :headers="headers" :items="users" class="pt-3">
        <template #top>
          <v-toolbar flat color="white">
            <v-card-title class="pl-0 pt-0"> Manage Users </v-card-title>
          </v-toolbar>
        </template>
        <template #item.fullName="{ item }">
          <span class="primary--text font-weight-bold">
            {{ item.fullName }}
          </span>
        </template>
        <template #item.sfProfile="{ item }">
          <span class="primary--text font-weight-bold">
            {{ item.sfProfile }}
          </span>
        </template>
        <template #item.teamName="{ item }">
          <span class="primary--text font-weight-bold">
            {{ item.teamName }}
          </span>
        </template>
        <template #item.email="{ item }">
          <span class="primary--text font-weight-bold">
            {{ item.email }}
          </span>
        </template>
        <template #item.roleName="{ item }">
          <span class="primary--text font-weight-bold">
            {{ item.roleName }}
          </span>
        </template>
        <template #item.status="{ item }">
          <span
            :class="{
              'ap-green--text': item.status === 'enabled',
              'ap-red--text': item.status === 'disabled',
            }"
          >
            {{ item.status === 'enabled' ? 'Active' : 'Inactive' }}
          </span>
        </template>
        <template #item.actions="{ item }">
          <v-icon
            v-if="userCanUpdateUsers"
            small
            class="mr-2"
            @click="editUser(item)"
          >
            $mdi-pencil
          </v-icon>
          <v-icon
            v-if="!item.isOwner && userCanDeleteUsers"
            small
            @click="openDialogToConfirmDeletionOfUser(item)"
          >
            $mdi-delete
          </v-icon>
        </template>
      </v-data-table>

      <v-snackbar v-model="showNotification" color="success" :timeout="3000">
        Invitation sent!
      </v-snackbar>
    </v-card>

    <UsersInviteForm class="mt-5" />

    <!-- TODO: This must be moved to a isolated component -->
    <ApDialog
      v-model="showEditUserWindow"
      title="Edit User"
      :actions="editActions"
      @cancel="closeEditUserDialog"
      @save="save"
    >
      <template #content>
        <div class="px-6">
          <v-text-field v-model="editedUser.firstName" label="First Name" />

          <v-text-field v-model="editedUser.lastName" label="Last Name" />

          <v-text-field v-model="editedUser.email" label="Email Address" />

          <v-select
            v-model="editedUser.status"
            :items="editedUserStatusOptions"
            label="Status"
            item-text="label"
            item-value="value"
          />

          <v-select
            v-model="editedUser.managerId"
            :items="editedUserPotentialManagers"
            label="Manager"
            item-text="fullName"
            item-value="id"
          />

          <v-alert
            v-if="errorMessage !== ''"
            dense
            text
            type="error"
            class="mx-5 my-0"
          >
            {{ errorMessage }}
          </v-alert>
        </div>
      </template>
    </ApDialog>

    <!-- TODO: This must be moved to a isolated component -->
    <ApDialog
      v-model="showDeleteUserConfirmation"
      title="Are you absolutely sure?"
      :actions="deleteActions"
      @cancel="closeDeleteUserConfirmationDialog"
      @confirm="removeUser"
    >
      <template #content>
        <div class="px-6">
          This action <b>cannot</b> be undone. This will permanently delete the
          <b>{{ userToDelete.email }}</b> user.<br />
          Please type <b>{{ userToDelete.email }}</b> to confirm.
          <v-form
            v-model="currentUserHasReenteredTheEmailAddressOfTheUserToDelete"
          >
            <v-text-field
              v-model="emailAddressOfTheUserToDeleteAsTypedInByTheCurrentUser"
              max-width="100"
              :rules="rulesForTheReenteringOfTheUserEmailAddress"
            />
          </v-form>
        </div>
      </template>
    </ApDialog>
  </div>
</template>
<script>
import ApDialog from '@/components/common/ApDialog'
import { mapGetters } from 'vuex'
import UsersInviteForm from './components/users-management/UsersInviteForm'
import { getUsers, deleteUser, updateUser } from '@/api/admin/users'

export default {
  components: {
    UsersInviteForm,
    ApDialog,
  },
  data() {
    return {
      currentUserHasReenteredTheEmailAddressOfTheUserToDelete: false,
      defaultUser: {
        firstName: '',
        lastName: '',
        email: '',
        status: '',
      },
      editedIndex: -1,
      editedUser: {
        firstName: '',
        lastName: '',
        email: '',
        status: '',
        manager: '',
      },
      editedUserDirectSubordinates: [],
      editedUserPotentialManagers: [],
      editedUserStatusOptions: [
        { label: 'Active', value: 'enabled' },
        { label: 'Inactive', value: 'disabled' },
      ],
      emailAddressOfTheUserToDeleteAsTypedInByTheCurrentUser: '',
      errorMessage: '',
      headers: [
        { text: 'Full Name', value: 'fullName' },
        { text: 'Salesforce Profile', value: 'sfProfile' },
        { text: 'Teams', value: 'teamName' },
        { text: 'Email Address', value: 'email' },
        { text: 'Roles', value: 'roleName' },
        { text: 'Status', value: 'status' },
        { text: 'Manager', value: 'managerFullName' },
        { text: 'Direct Subordinates', value: 'subordinatesCount' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      rulesForTheReenteringOfTheUserEmailAddress: [
        (v) => !!v || 'Field is required',
        (v) =>
          v === this.userToDelete.email ||
          'Must match the email address of the user to delete.',
      ],
      showDeleteUserConfirmation: false,
      showEditUserWindow: false,
      showNotification: false,
      users: [],
      userToDelete: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['userCanUpdateUsers', 'userCanDeleteUsers']),
    editActions() {
      return [
        {
          event: 'cancel',
          label: 'Cancel',
          text: true,
          color: 'primary',
        },
        {
          event: 'save',
          label: 'Save',
          text: true,
          color: 'primary',
        },
      ]
    },
    deleteActions() {
      return [
        {
          event: 'cancel',
          label: 'Cancel',
          text: true,
        },
        {
          event: 'confirm',
          label: 'I understand the consequences, delete this user',
          color: 'error',
          disabled:
            !this.currentUserHasReenteredTheEmailAddressOfTheUserToDelete,
        },
      ]
    },
  },
  watch: {
    showEditUserWindow(newValue) {
      if (newValue === true) {
        this.setEditedUserPotentialManagers()
        this.setEditedUserDirectSubordinates()
      }
    },
  },
  async created() {
    this.users = await getUsers()
  },
  methods: {
    editUser(user) {
      this.editedIndex = this.users.indexOf(user)
      this.editedUser = Object.assign({}, user) // Copy the user object.
      this.showEditUserWindow = true
    },
    openDialogToConfirmDeletionOfUser(user) {
      this.userToDelete = user
      this.showDeleteUserConfirmation = true
    },
    closeDeleteUserConfirmationDialog() {
      this.userToDelete = {}
      this.showDeleteUserConfirmation = false
      this.emailAddressOfTheUserToDeleteAsTypedInByTheCurrentUser = ''
    },
    removeUser() {
      const indexOfUserToDelete = this.users.indexOf(this.userToDelete)
      const vm = this

      deleteUser(this.userToDelete.id).then(
        () => {
          vm.users.splice(indexOfUserToDelete, 1)
          vm.closeDeleteUserConfirmationDialog()
        },
        (error) => {
          const errorJson = error.response.data
          const errorInfo = errorJson['error']

          vm.errorMessage = errorInfo['message']
        }
      )
    },
    closeEditUserDialog() {
      this.showEditUserWindow = false
      setTimeout(() => {
        this.editedIndex = -1
        this.editedUser = Object.assign({}, this.defaultUser)
        this.editedUserDirectSubordinates = []
        this.editedUserPotentialManagers = []
      }, 300)
    },
    save() {
      const vm = this
      const dataToPost = {}

      Object.assign(dataToPost, this.editedUser)

      updateUser(this.editedUser.id, dataToPost).then(
        async () => {
          Object.assign(vm.users[vm.editedIndex], vm.editedUser)
          vm.closeEditUserDialog()
          this.users = await getUsers()
        },
        (error) => {
          const errorJson = error.response.data
          const errorInfo = errorJson['error']

          vm.errorMessage = errorInfo['message']
        }
      )
    },
    setEditedUserPotentialManagers() {
      this.editedUserPotentialManagers = this.users.filter(
        (user) =>
          user.id !== this.editedUser.id &&
          !this.editedUser.subordinates.includes(user.id)
      )
      this.editedUserPotentialManagers.splice(0, 0, { id: null, fullName: '' })
    },
    setEditedUserDirectSubordinates() {
      this.editedUserDirectSubordinates = this.users.filter(
        (user) => user.managerId === this.editedUser.id
      )
    },
  },
}
</script>
